/// <reference path='../../_reference.ts' />
module Apex.Banking.Controllers {

    export interface AccountsModalScope extends angular.IScope {
        DataSources: {
            banks?: any,
            currencies?: any,
            accounts?: any,
            providers?: any
        },
        Model: {
            account?: any,
            IdAcc?: any,
            ID?: any
        },
        events: {
            save: Function
        },
        modalInstance: any,
        accountsForm: any
    }
    export class AccountsModal extends Core.Controller {

        public static $inject = [Globals.scope, 'data', Globals.modalInstance, Globals.toaster, 'accountService', 'bankingService','$timeout'];

        constructor(private $scope: AccountsModalScope, private data: any, private $uibModalInstance, private toaster,
            private accountService: Account.Services.Account, private bankingService: Banking.Services.Banking, private $timeout) {
            super(arguments, AccountsModal.$inject);
            $scope.modalInstance = $uibModalInstance;
            this.initialize($scope);
            if (data) {
                // Model.account.BankID
                $scope.Model.ID = data.ID;
                $scope.Model.account.Bank = $scope.Model.account.BankID = data.BankID;
                $scope.Model.account.BankProvider = data.BankProvider;
                $scope.Model.account.bankProv = data.BankProvider;

                $scope.Model.IdAcc = data.IdAcc;
                $scope.Model.account.IbanAcc = data.IbanAcc;
                $scope.Model.account.Name = data.Name;
                $scope.Model.account.currencyCodes = [data.CurrencyCode];

            }
            $scope.$watch("DataSources.accounts", (val) => {
                if (val) {
                    this.$timeout(() => {
                        Apex.Core.Utils.Helper.safeApply(this.$scope, () => {
                            this.$scope.Model.account.IdAcc = this.$scope.Model.IdAcc;
                            this.$scope.Model.account.Acc = this.$scope.Model.IdAcc;
                        });

                    });
                }
            });
        }
        initialize = (scope: AccountsModalScope) => {
            scope.DataSources = {
                accounts: Core.Kendo.GridView.initAutoQueryDataSource(this.accountService.accountsQueryUrl)
            };
            scope.Model = {
                account: {
                    BankID: null,
                    Bank: null,
                    bankProv: null
                }
            };
            scope.events = {
                save: this.save
            };
            this.getBankProviders();
            this.getBanks();
            this.getCurrencies();
        }

        getCurrencies = () => {
            this.accountService.getCurrency().then(result => {
                _.each(result.Result, (x: any) => { x.textTemplate = x.Code + ' - ' + x.Name });
                this.$scope.DataSources.currencies = result.Result;
            });
        }
        getBanks = () => {
            this.bankingService.getBanks().then(r => {
                if (r) {
                    this.$scope.DataSources.banks = r.Result;
                }
            });
        }
        getBankProviders = () => {
            this.bankingService.getBankProvidersInfo().then(r => {
                if (r) {
                    this.$scope.DataSources.providers = r.Result;
                }
            });
        }

        save = () => {
            if (!this.$scope.Model.account.Bank || !this.$scope.Model.account.bankProv
                || !this.$scope.Model.account.Acc || !this.$scope.Model.account.IbanAcc
                || !this.$scope.Model.account.currencyCodes) {
                if (!this.$scope.Model.account.currencyCodes) {
                    $(".k-multiselect").css("border-color", "#f44336");
                }
                this.toaster.pop("warning", "შენიშვნა!", "გთხოვთ შეავსოთ აუცილებელი ველები!");
                return;
            }
            var bAccounts = [];
            var bAcc = {
                ID: this.$scope.Model.ID,
                BankID: this.$scope.Model.account.Bank,
                IdAcc: this.$scope.Model.account.Acc,
                IbanAcc: this.$scope.Model.account.IbanAcc,
                BankProvider: this.$scope.Model.account.bankProv,
                Name: this.$scope.Model.account.Name
            };
            _.each(this.$scope.Model.account.currencyCodes, (x: any) => {
                bAccounts.push({ BankID: bAcc.BankID, IdAcc: bAcc.IdAcc, IbanAcc: bAcc.IbanAcc, CurrencyCode: x, BankProvider: bAcc.BankProvider, Name: bAcc.Name, ID: bAcc.ID });
            });


            this.bankingService.saveBAccount(bAccounts).then(result => {
                if (result && result.Result) {
                    this.$uibModalInstance.close(true);
                }
            });
        }
    }
}