/// <reference path='./_reference.ts' />
module Apex.Banking {
    'use strict';

    var apexBanking = new Core.Module('banking', ['apex.webUI.uiFrame', 'apex.webUI.components', 'apex.webUI.account'], Banking);
    var routes: Array<Core.Interfaces.IRoute> =
        [
            {
                state: 'banking',
                url: '^/banking',
                template: '<ui-view/>',
                navigation: { caption: 'banking', navigationClass: 'md md-account-balance' },
                childRoutes: [
                    {
                        state: 'bankAccounts',
                        url: '/bankAccounts',
                        templateUrl: 'bankAccounts.html',
                        controller: Banking.Controllers.AccountsController.getName(),
                        navigation: {
                            caption: 'bAccount'
                        }
                    },
                    {
                        state: 'statement',
                        url: '/statement?code',
                        templateUrl: 'statement.html',
                        controller: Banking.Controllers.Statement.getName(),
                        navigation: {
                            caption: 'statement'
                        }

                    },
                    {
                        state: 'BankOperation',
                        url: '/BankOperation',
                        controller:Banking.Controllers.BankOperation.getName(),
                        templateUrl: 'bankOperation.html',
                        navigation: {
                            caption: 'transactions'
                        }
                    },
                    {
                        state: 'AccountExpretion',
                        url: '/AccountExpretion',
                        controller:Banking.Controllers.AccountFilter.getName(),
                        templateUrl: 'account.filter.html',
                        navigation: {
                            caption: 'AccFilter'
                        }
                    },
                    ,
                    {
                        state: 'PosList',
                        url: '/PosList',
                        controller:Banking.Controllers.PosList.getName(),
                        templateUrl: 'pos.list.html',
                        navigation: {
                            caption: 'posLis'
                        }
                    }
                ]
            }
        ];

    var bankTokenRoutes: Array<Core.Interfaces.IRoute> = [
        {
            state: 'bankingToken',
            url: '/bankToken',
            template: '<ui-view/>',
            childRoutes: [
            {
                    state: 'bog',
                    url: '/bog?code',
                    controller: Banking.Controllers.BankOfGeorgia.getName()
            }]
        }
    ];

  

    apexBanking.regRoutes(routes, 'apex');
    apexBanking.regRoutes(bankTokenRoutes, null);
}