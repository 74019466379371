/// <reference path='../_reference.ts' />
module Apex.Banking.Controllers {

    export interface BankOperationScope extends angular.IScope {
        Components: {
            BankOperationGrid: Apex.Components.IApxGrid
        },
        Model: {
            bankAccounts?: any,
            selectedRow?: Interface.BDData,
            date?: string,
            operations: {
                dataSource?: any
            },
            BdiAccount: {
                dataSource?: any[],
                selectedItem?: any
            },
            refreshPointer: boolean
        },
        Filters: {
            dateBitween?: any,
            acc?: any,
            operationType?: any[]
        }
        bankProvidersInfo: any[],
        events: {
            refresh: Function,
            save: Function,
            togle_click: Function,
            autoFillOnClick: Function,
            clearValue: Function,
            delete: Function,
            selectAll: Function,
            unselectAll: Function
            // checkedChange: Function
        },
        DType: {
            val: boolean
        };
        mystyle: {
            gridContentStyle: any;
            docPanelStyle: any;
        },
        disabled: {
            selectBtn: boolean,
            disableAutoFillBtn: boolean
        }
        panelExpanded: boolean;
        selectedItemsCount: number;
        getOperations: Function;
        getBdiAccount: Function;
        array: {
            array: any
        }
    }


    export class BankOperation extends Core.Controller {

        public static $inject = [Globals.scope, Globals.toaster, 'bankingService', 'accountService', '$timeout', '$translate'];

        constructor(private $scope: BankOperationScope, private toaster: any, private bankingService: Services.Banking,
            private accountService: Apex.Account.Services.Account, private $timeout, private $translate) {
            super(arguments, BankOperation.$inject);
            this.initialize();
            $timeout(() =>
                $scope.$watch("DType.val", (val) => {
                    if (val != 'undefined')
                        this.refresh();
                })
            );
        }



        initialize = () => {
            this.$scope.getOperations = this.getOperations;
            this.$scope.selectedItemsCount = 0;
            this.$scope.disabled = {
                disableAutoFillBtn: true,
                selectBtn: true
            }
            this.$scope.panelExpanded = false;
            this.$timeout(() => {
                var pageBody_Width = $("#pageBody").width();
                var docPanelRightHeader_Width = $("#doc-panel-right-header").width();

                var panelWidth =
                    this.$scope.mystyle = {
                        docPanelStyle: {
                            "float": "left",
                            "width": 32 * 100 / pageBody_Width + "%"
                        },
                        gridContentStyle: {
                            "float": "left",
                            "width": 100 - 32 * 100 / pageBody_Width + "%"
                        }
                    }
            });

            this.$scope.DType = {
                val: false
            };
            this.$scope.Model = {
                operations: {
                },
                BdiAccount: {
                    dataSource: [],
                    selectedItem: null

                },
                refreshPointer: true
            };
            this.$scope.getBdiAccount = this.getBdiAccounts;
            this.$scope.Filters = {
                dateBitween: {},
                operationType: [{ val: false, title: "ჩარიცხვები" }, { val: true, title: "გადარიცხვები" }, { val: null, title: "ყველა" }]
            };
            this.$scope.Components = {
                BankOperationGrid: this.initStatementGrid(),
            }
            this.$scope.events = {
                refresh: this.refresh,
                save: this.save,
                togle_click: this.togle_click,
                autoFillOnClick: this.autoFill_OnClick,
                clearValue: this.clearValue,
                delete: this.delete,
                selectAll: this.selectAll,
                unselectAll: this.unselectAll
            };

            this.getBankProvidetsInfo();
            this.getBankAccounts();
        }
        onCompanyChange = (company: Apex.UIFrame.Interfaces.ICompanyInfo) => {
            this.$scope.Model.selectedRow = null;
            this.$scope.selectedItemsCount = 0;
            if (this.$scope.Components.BankOperationGrid.grid)
                this.$scope.Components.BankOperationGrid.grid.clearSelection();
            this.getBankAccounts();
        }
        selectAll = () => {
            // Apex.Banking.Controllers.BankOperation.checkedChange(new Event());
            var dataSource = this.$scope.Components.BankOperationGrid.dataSource.data();
            _.forEach(dataSource, (item) => {
                if (item.CR && item.DB && !item.DocId && !item.isSelected){
                    item.isSelected = true;
                    this.$scope.selectedItemsCount++;
                }
            });
        }
        unselectAll = () => {
            var dataSource = this.$scope.Components.BankOperationGrid.dataSource.data();
            _.forEach(dataSource, (item) => {
                if (item.isSelected){
                    item.isSelected = false;
                    this.$scope.selectedItemsCount--;
                }
            });
        }
        delete = () => {
            var selectedList = this.$scope.Components.BankOperationGrid.dataSource.data();
            var forDeleteList = _.filter(selectedList, (item) => {
                return item.isSelected == true;
            });
            // var dataItem: Interface.IPosList = this.$scope.Components.posListGrid.grid.dataItem(selected);
            if (forDeleteList && forDeleteList.length > 0) {
                Core.Utils.ModalForm.openDeleteConfirmations(() => {
                    this.$scope.Components.BankOperationGrid.gridView.showLoading();
                    var bDDataIds = _.map(forDeleteList, (item) => {
                        return item.Id;
                    });
                    // this.$scope.Components.BankOperationGrid.gridView.hideLoading();
                    this.bankingService.deleteBDData(bDDataIds).then(r => {
                        this.$scope.Components.BankOperationGrid.gridView.hideLoading();
                        this.getBankOperation();
                    }).catch(error => { this.$scope.Components.BankOperationGrid.gridView.hideLoading(); });

                });
            }
        }
        getBdiAccounts = (item) => {
            return this.bankingService.getBdiAccounts(item);
        }
        getOperations = () => {
            return this.accountService.getOpers("1000");
        }
        public static checkedChange = (evn) => {
            var scope: BankOperationScope = <BankOperationScope>angular.element($("#pageBody")).scope();
            if (!scope.Model.selectedRow.DB || !scope.Model.selectedRow.CR || scope.Model.selectedRow.DocId) {
                evn.preventDefault();
                return;
            }
            Core.Utils.Helper.safeApply(scope, () => {
                if (!scope.Model.selectedRow.isSelected)
                    scope.selectedItemsCount++;
                else
                    scope.selectedItemsCount--;
            });
        }
        save = (val) => {
            var dataSource = this.$scope.Components.BankOperationGrid.dataSource.data();
            var selectedData = _.filter(dataSource, r => { return r.isSelected == true });

            if (selectedData.length > 0) {
                if (val && val.OperID) {
                    kendo.ui.progress($("#pageBody"), true);
                    this.bankingService.recordInTheBalanceSheet(selectedData, val.OperID).then(r => {
                        kendo.ui.progress($("#pageBody"), false);
                        if (!r.statusCode) {
                            this.toaster.pop('success', "შეტყობინება", "ოპერაცია წარმატებიტ დასრულდა!");
                        }
                        this.refresh();
                    }).catch(error => { kendo.ui.progress($("#pageBody"), false); });
                } else {
                    this.toaster.pop('warning', "შენიშვნა", "გთხოვთ აირჩიოთ ოპერაციის ტიპი!");
                }
            } else {
                this.toaster.pop('warning', "შენიშვნა", "გთხოვთ მონიშნოთ ჩანაწერი!");
            }
        }

        refresh = () => {
            
            this.$scope.disabled.selectBtn=true;
            this.$scope.Model.refreshPointer = false;
            this.$scope.selectedItemsCount = 0;
            this.getBankOperation();
        }

        initStatementGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.getGridDatasource(),
                    columns: this.statementColumns,
                    settings: { editble: { disableEditRow: true }, pageable: true },
                    parentScope: this.$scope,
                    gridEvents: [
                        {
                            eType: Components.EventType.Change,
                            eFunction: (e) => {
                                Core.Utils.Helper.safeApply(this.$scope, () => {
                                    if (this.$scope.Model.selectedRow === <Interface.BDData>this.$scope.Components.BankOperationGrid.gridView.selectedItem())
                                        return;
                                    this.$scope.Model.selectedRow = this.$scope.Components.BankOperationGrid.gridView.selectedItem();

                                    if (this.$scope.Model.selectedRow !== null && !!this.$scope.Model.selectedRow) {
                                        // this.refreshBranch();
                                        var date = new Date(parseInt(this.$scope.Model.selectedRow.DocDate.toString().substr(6)));
                                        this.$scope.Model.date = date.getUTCDate() + "/" + date.getUTCMonth() + 1 + "/" + date.getUTCFullYear();
                                    }
                                });
                            }
                        },
                        {
                            eType: Components.EventType.ISelectedChange,
                            customFunction: (e: Apex.Components.Directives.ISelectedEvent) => {

                                if ((<Interface.BDData>e.object).DB && (<Interface.BDData>e.object).CR && !(<Interface.BDData>e.object).DocId)
                                    e.AllowToChange = true;
                                else e.AllowToChange = false;
                                if (e.AllowToChange && e.newValue)
                                    this.$scope.selectedItemsCount++;
                                else if (e.AllowToChange && !e.newValue)
                                    this.$scope.selectedItemsCount--;
                                return e;
                            }
                        }
                    ]

                };

            return grid;
        }
        getGridDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                pageSize: 100,
                aggregate: [
                    { field: "isSelected", aggregate: "count" },
                    { field: "Amount", aggregate: "sum" },
                    { field: "AmountEQU", aggregate: "sum" }
                ],
                schema: {
                    model: {
                        id: "Id",
                        fields: {
                            isSelected: { type: 'boolean' },
                            BdiType: { type: "number" },
                            DB: { type: "string" },
                            CR: { type: "string" },
                            DocDate: { type: "date" },
                            OpCode: { type: "string" },
                            DocNum: { type: "string" },
                            Amount: { type: "number" },
                            AmountEQU: { type: "number" },
                            SaxazCode: { type: "string" },
                            CRTime: { type: "date" }
                        }
                    }
                }
            });

        }


        getBankProvidetsInfo = () => {
            this.bankingService.getBankProvidersInfo().then((result: any) => {
                this.$scope.bankProvidersInfo = result.Result;
            });
        }
        getBankAccounts = () => {
            this.bankingService.getBAccounts().then(result => {
                _.each(result.Results, (x: any) => {
                    x.tempalteName = x.IbanAcc + ' ' + x.CurrencyCode;
                });
                this.$scope.Model.bankAccounts = result.Results;
            });
        }
        getBankOperation = () => {
            if (!this.$scope.Filters.dateBitween.fromDate || !this.$scope.Filters.dateBitween.toDate)
                return;
            var d1 = Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.Filters.dateBitween.fromDate);
            var d2 = Apex.Core.Utils.Helper.toLocalTimeISO(this.$scope.Filters.dateBitween.toDate);
            var acc = this.$scope.Filters.acc;
            var bdiType = this.$scope.DType.val;
            
            this.$scope.Components.BankOperationGrid.gridView.showLoading();
            this.bankingService.getOperation(d1, d2, acc, bdiType).then(r => {
                if (r && r.Result) {
                    var data = r.Result;
                    for (var i = 0; i < data.length; i++) {
                        data[i].isSelected = false;
                        if ((<Interface.BDData>data[i]).DB && (<Interface.BDData>data[i]).CR && !(<Interface.BDData>data[i]).DocId)
                            this.$scope.disabled.selectBtn = false;
                    }
                    this.$scope.disabled.disableAutoFillBtn = r.Result.length == 0;
                    this.$scope.Components.BankOperationGrid.dataSource.data(data);
                    this.$scope.Components.BankOperationGrid.gridView.hideLoading();
                }
            })
        }

        clearValue(dataItem: Interface.BDData) {

            if (!dataItem.isSelected) {
                if (dataItem.BdiType) {
                    dataItem.DB = null;
                } else dataItem.CR = null;
            }

        }

        private statementColumns: Array<Core.Interfaces.IGridColumn> = [
            {
                field: 'isSelected', title: ' ', titleTranslated: "isSelected", width: 40, filterable: 'boolean', template: `
                <div class="checkbox checkbox-styled checkbox-danger" style="margin-bottom: 0px;">
                    <label>
                        <input type="checkbox"  onclick="Apex.Banking.Controllers.BankOperation.checkedChange(event)" ng-model="dataItem.isSelected" ng-true-value="true" ng-false-value="false" >
                        <span ></span>
                    </label>
                </div>`
            },
            {
                field: 'BdiType', title: 'DB', titleTranslated: 'operationType', width: 100,
                filterable: 'custom',
                filterTemplate: (args) => {
                    var elem: JQuery = args.element;
                    elem.kendoDropDownList({
                        dataSource: [
                            { value: null, text: this.$translate.instant('ALL') },
                            { value: 1, text: this.$translate.instant('CR') },
                            { value: 0, text: this.$translate.instant('DB') }
                        ],
                        valuePrimitive: true,
                        dataTextField: "text",
                        dataValueField: "value",

                    })
                },
                template: `
                <div ng-if="dataItem.BdiType==true">
                    <i class="fa fa-upload text-danger"></i>
                    <i style="padding-left:5px; font-style: normal;">გადარიცხვა</i>
                </div> 
                <div ng-if="dataItem.BdiType==false">
                    <i class="fa fa-download  text-success"  style"float:left"></i>
                    <i style="padding-left:5px; font-style: normal;">ჩარიცხვა</i>
                </div>`
                , footerTemplate: '<span translate=Count></span> #=kendo.toString(data.isSelected? data.isSelected.count:0) #'
            },
            {
                field: 'RecordetDocuments', width: 130, titleTranslated: "status", filterable: false, template: `
                <div ng-if="dataItem.DB && dataItem.CR && !dataItem.DocId">
                    <i class="fa fa-circle text-warning" ></i>
                    <i style="padding-left:5px; font-style: normal;">მზადაა ასასახად</i>
                </div>
                <div ng-if="(!dataItem.DB || !dataItem.CR) && (!dataItem.DocId)">
                    <i class="fa fa-circle text-danger" ></i>
                    <i style="padding-left:5px; font-style: normal;">პროექტი</i>
                </div>
                <div ng-if="dataItem.DocId">
                    <i class="fa fa-circle text-success" ></i>
                    <i  style="padding-left:5px; font-style: normal;">ბალანსში ასახული</i>
                </div>`
            },
            {
                field: 'DB', title: 'DB', titleTranslated: 'DB', width: 120, template: `
                <div ng-controller="bankOperationController as ctrl" ng-if="!dataItem.DB">
                    <apx-dropdown       ng-model="dataItem.DB"                                        
                                        apx-datasource="Model.BdiAccount.dataSource"
                                        apx-value-id="Acc" 
                                        apx-value-text="Acc"
                                        apx-get-data="getBdiAccount(dataItem)">
                    </apx-dropdown>
                </div> 
                <div ng-if="dataItem.DB">
                    <span>{{dataItem.DB}}</span>
                    <div ng-if="dataItem.BdiType && !dataItem.DocId" style="float:right">
                        <i class="fa fa-times" aria-hidden="true" ng-click="options.parentScope.events.clearValue(dataItem)"></i>
                    </div>
                 </div>
                 
            ` },
            {
                field: 'CR', title: 'CR', titleTranslated: 'CR', width: 120, template: `
                <div ng-controller="bankOperationController as ctrl" ng-if="!dataItem.CR">
                    <apx-dropdown       ng-model="dataItem.CR" 
                                        apx-datasource="Model.BdiAccount.dataSource"
                                        apx-value-id="Acc" 
                                        apx-value-text="Acc"
                                        apx-get-data="getBdiAccount(dataItem)">
                    </apx-dropdown>
                </div> 
               <div ng-if="dataItem.CR">
                    <span>{{dataItem.CR}}</span>
                    <div ng-if="!dataItem.BdiType && !dataItem.DocId" style="float:right">
                        <i class="fa fa-times" aria-hidden="true" ng-click="options.parentScope.events.clearValue(dataItem)"></i>
                    </div>
               </div>
            ` },
            { field: 'DocDate', title: 'DocDate', width: 120, sortable: { initialDirection: "desc" }, titleTranslated: 'DATE', format: Core.Kendo.Format.Date, withTooltip: true },
            { field: 'OpCode', titleTranslated: 'OpDetId', width: 90 },
            { field: 'DocNum', titleTranslated: 'DcoNumber', width: 100 },
            {
                field: 'Amount', titleTranslated: 'amount', width: 100
                , footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.Amount ? data.Amount.sum.toFixed(2) : 0) #',
                format: Core.Kendo.Format.Number
            },
            {
                field: 'AmountEQU', titleTranslated: 'AmountEQU'
                , footerTemplate: '<span translate=sum> </span> #= kendo.toString(data.AmountEQU ? data.AmountEQU.sum.toFixed(2) : 0) #',
                format: Core.Kendo.Format.Number
            },
            { field: 'SaxazCode', titleTranslated: 'saxazCode' },
            { field: 'CRTime', titleTranslated: 'crtime', format: Core.Kendo.Format.Date }
        ];

        ///#region  Events
        autoFill_OnClick = () => {
            var account = _.findWhere(this.$scope.Model.bankAccounts, { IbanAcc: this.$scope.Filters.acc });
            var data = this.$scope.Components.BankOperationGrid.dataSource.data();
            kendo.ui.progress($("#pageBody"), true);
            this.bankingService.autoFillBanckAccount(data, (<any>account).IdAcc, (<any>account).IbanAcc).then(r => {
                kendo.ui.progress($("#pageBody"), false);
                if (r.Notification)
                    this.toaster.pop('warning', "შეტყობინება", r.Notification);
                else if (!r.statusCode) {
                    this.toaster.pop('success', "შეტყობინება", "ოპერაცია წარმატებით დასრულდა!");
                }
                this.refresh();
            }).catch(error => { kendo.ui.progress($("#pageBody"), false); });
        }
        togle_click = () => {
            var pageBody_Width = $("#pageBody").width();
            var docPanelRightHeader_Width = $("#doc-panel-right-header").width();

            if (this.$scope.panelExpanded) {
                this.$scope.mystyle = {
                    docPanelStyle: {
                        "float": "left",
                        "width": 32 * 100 / pageBody_Width + "%"
                    },
                    gridContentStyle: {
                        "float": "left",
                        "width": 100 - 32 * 100 / pageBody_Width + "%"
                    }
                }
            } else {
                this.$scope.mystyle = {
                    docPanelStyle: {
                        "float": "left",
                        "width": 600 * 100 / pageBody_Width + "%"
                    },
                    gridContentStyle: {
                        "float": "left",
                        "width": 100 - 600 * 100 / pageBody_Width + "%"
                    }
                }
            }
            this.$scope.panelExpanded = !this.$scope.panelExpanded;
        }
        ///#endregion

    }
}
