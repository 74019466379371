/// <reference path='../_reference.ts' />
module Apex.Banking.Controllers {
    export interface AccountFilterScope extends angular.IScope {
        erandPopUp?: kendo.ui.ContextMenu;
        Components: {
            accFilterGrid: Apex.Components.IApxGrid
        }
        events: {
            refresh: Function,
            add: Function,
            delete: Function,
            menuOpen?: Function,
            upPriority: Function,
            downPriority: Function
        },
        model: {
            selectedItem?: Interface.AccFilter,
            disableDeleteBtn?: boolean,
            disableUpBtn?: boolean,
            disableDownBtn?: boolean,
            refreshPointer:boolean
        }

    }

    export class AccountFilter extends Core.Controller {
        public static $inject = [Globals.scope, Globals.toaster, 'bankingService', '$timeout'];
        constructor(private $scope: AccountFilterScope, private toaster: any, private bankingService: Services.Banking, private $timeout) {
            super(arguments, AccountFilter.$inject);
            this.Initialization($scope);
        }

        Initialization(scope: AccountFilterScope) {
            scope.model = {
                disableDeleteBtn: true,
                disableDownBtn: true,
                disableUpBtn: true,
                refreshPointer:true
            };
            scope.events = {
                refresh: this.refresh,
                add: this.add,
                delete: this.deleteAccFilter,
                menuOpen: () => {
                    if (!this.$scope.model.selectedItem) {
                        $("#filter-priority").css("opacity", "0.4");
                        $("#filter-delete").css("opacity", "0.4");
                    }
                    else {
                        $("#filter-priority").css("opacity", "1");
                        $("#filter-delete").css("opacity", "1");
                    }
                },
                upPriority: this.upPriority,
                downPriority: this.downPriority
                // setAsDefault:this.setAsDefault
            }
            scope.Components = {
                accFilterGrid: this.initAccFilterGrid()
            }
        }
        onCompanyChange = (company: Apex.UIFrame.Interfaces.ICompanyInfo) => {
            this.$scope.model.disableDeleteBtn=
            this.$scope.model.disableDownBtn=
            this.$scope.model.disableUpBtn=true;
            this.$scope.model.selectedItem=null;
            this.refresh();
            this.$scope.Components.accFilterGrid.grid.clearSelection();
        }
        upPriority = () => {
            if (this.$scope.model.selectedItem) {
                this.$scope.model.selectedItem.Priority = this.$scope.model.selectedItem.Priority == null || this.$scope.model.selectedItem.Priority == undefined
                    ? 1 : this.$scope.model.selectedItem.Priority + 1;
                this.$scope.model.disableDownBtn = this.$scope.model.selectedItem.Priority==0;
                this.saveFilterCriteria(this.$scope.model.selectedItem);
            }
        }
        downPriority = () => {
            if (this.$scope.model.selectedItem) {
                this.$scope.model.selectedItem.Priority = this.$scope.model.selectedItem.Priority == null || this.$scope.model.selectedItem.Priority == undefined
                    ? 0 : this.$scope.model.selectedItem.Priority - 1;
                this.$scope.model.disableDownBtn = this.$scope.model.selectedItem.Priority==0;
                this.saveFilterCriteria(this.$scope.model.selectedItem);
            }
        }
        refresh = () => {
            this.$scope.model.refreshPointer=false;
            this.$scope.Components.accFilterGrid.gridView.showLoading();
            this.bankingService.getAccFilter().then(r => {
                this.$scope.Components.accFilterGrid.gridView.hideLoading();
                if (r && r.Result != undefined)
                    this.$scope.Components.accFilterGrid.dataSource.data(r.Result);
            }).catch(error => this.$scope.Components.accFilterGrid.gridView.hideLoading());
        }

        add = () => {
            var dlg = Core.Utils.ModalForm.openModalForm('acc.filter.modal.html', AccFilterModal.getName(), {}, Core.Utils.ModalSize.Medium);
            dlg.result.then(result => {
                if (result) {
                    this.saveFilterCriteria(result);
                }
            });
        }
        deleteAccFilter = () => {
            var seletedItem = this.$scope.Components.accFilterGrid.grid.select();
            if (seletedItem) {
                var seletedRow: Interface.AccFilter = this.$scope.Components.accFilterGrid.grid.dataItem(seletedItem).toJSON();
                if (seletedRow) {
                    Core.Utils.ModalForm.openDeleteConfirmations(() => {
                        if (seletedRow) {
                            this.bankingService.deleteAccFilter(seletedRow.Id).then(r => {
                                this.$scope.Components.accFilterGrid.grid.removeRow(seletedItem);
                            });
                        }
                    });
                }
            }
        }

        saveFilterCriteria = (accFilter: Interface.AccFilter) => {
            this.bankingService.saveAccFilter(accFilter).then(r => {
                if (r && r.Result > 0) {
                    // this.toaster.pop("success", "შეტყობინება", "მონაცემების შენახვა წარმატებით დასრულდა.");
                    this.refresh();
                }
            });
        }

        initAccFilterGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.getGridDatasource(),
                    columns: this.accFilterColumns,
                    settings: { editble: { disableEditRow: true } },

                    gridEvents: [
                        {
                            eType: Components.EventType.Change,
                            eFunction: (e) => {
                                this.$scope.model.selectedItem = e.sender.dataItem(e.sender.select()).toJSON();
                                Core.Utils.Helper.safeApply(this.$scope, () => {
                                    this.$scope.model.disableDeleteBtn = this.$scope.model.selectedItem == null;
                                    this.$scope.model.disableDownBtn = this.$scope.model.selectedItem == null || this.$scope.model.selectedItem.Priority == 0;
                                    this.$scope.model.disableUpBtn = this.$scope.model.selectedItem == null;
                                });
                            }
                        }
                    ]

                };

            return grid;
        }
        getGridDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                schema: {
                    model: {
                        id: "Id",
                        fileds:{
                            FilterStringCriteria:{type:"string"},
                            Value:{type:"string"},
                            Priority:{type:"string"}
                        }
                    }
                },
                aggregate:[
                    {field:"FilterStringCriteria",aggregate:"count"}
                ]
            });
        }


        private accFilterColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'FilterStringCriteria', titleTranslated: "FilterCriteria", width: 300 
            , footerTemplate:'<span translate=Count></span> #=kendo.toString(data.FilterStringCriteria? data.FilterStringCriteria.count:0) #'},
            { field: 'Value', titleTranslated: "Value", width: 40 },
            {
                field: 'Priority', title: 'პრიორიტეტი', width: 30
                // template: `
                // <apx-checkbox ng-disabled="true" without-translate ng-model="dataItem.Priority" class="text-center" style="margin-bottom: 0px;"></apx-checkbox>
                // `
            }
        ];

    }




}

