/// <reference path='../_reference.ts' />
module Apex.Banking.Controllers {
    declare var window: any;
    export class BankOfGeorgia extends Core.Controller {

        public static $inject = [
            Globals.stateParams
        ];

        constructor($stateParams) {
            super(arguments, BankOfGeorgia.$inject);

            

            var retVal = JSON.parse($stateParams.code);

            window.opener.receiveDataFromPopup(retVal.access_token);

            window.close();

            //open(window.location.origin, '_self').close();

            //window.top.close();

          

            //window.open('location', '_self', '');
            //window.close();
        }
    }
}