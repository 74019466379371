/// <reference path='../../_reference.ts' />
module Apex.Banking.Controllers {
    export interface AuthProviderScope extends angular.IScope {
        account: any
        events: {
            closeForm: Function,
            save: Function,
            onLoad: Function
        },
        token: {
            Code: string
        }
        BogUrl: {
            url: string
        }
        Frame:{
            content?:string;
        }

    }
    declare var window: any;

    export class AuthProvidersModal extends Core.Controller {
        public static $inject = [Globals.scope, Globals.translate, 'data', 'bankingService', Globals.modalInstance, Globals.toaster, "$timeout", '$sce', '$location', '$rootScope'];
        constructor(private $scope: AuthProviderScope, private $translate, data: any, private bankingService,
            private $uibModalInstance, private toaster, public $timeout, private $sce,
            private $location, private $rootScope) {
            super(arguments, AuthProvidersModal.$inject);
            $scope.account = data;      
            this.initialization();
           

            this.$timeout(() => {
                let textBox = $("#dgp-code");
                textBox.focus();
                textBox.on("keyup", (_event) => {
                    if (_event.keyCode == 13)
                        this.save();
                });
                if (data.BankProvider == "BOG") {
                    $(".modal-dialog").removeClass("modal-sm").addClass("modal-lg");
                }
            });
        }

        initialization = () => {
            this.$scope.Frame={};

            this.$scope.events = {
                closeForm: this.closeForm,
                save: this.save,
                onLoad: this.onload
            }
            this.$scope.token = {
                Code: ""
            }
            this.$scope.BogUrl = {
                url: this.$sce.trustAsResourceUrl(Global.Settings.serviceUrl + "/json/reply/GetBogAuth")
            };


        
        }

        onload = () => {
        
        }

        closeForm = () => {
            this.$uibModalInstance.close(false);
        }
        save = () => {
            if (this.$scope.token.Code) {
                this.$uibModalInstance.close(this.$scope.token.Code);
            }
            else if(this.$scope.account.BankProvider=="BOG"){
                this.bankingService.getBogToken().then(r=>{                    
                    if(r && r.Token){
                        this.$uibModalInstance.close(r.Token);
                    }
                })
            }
        }

        // validateForm = (): boolean => {
        //     if (this.$scope.branch.Name == undefined || this.$scope.branch.Name == "" || this.$scope.branch.Name.trim() == "") {
        //         this.toaster.pop('error', "Error", "გთხოვთ შეავსოთ '" + this.$translate.instant('nameCaption') + "' ველი");
        //         return false;
        //     }
        //     return true;
        // }
    }
}