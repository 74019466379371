/// <reference path='../_reference.ts' />
module Apex.Banking.Controllers {
    export interface IAccountsScope extends angular.IScope {
        Components: {
            bankAccountGrid: Apex.Components.IApxGrid
        },
        Model: {
            disableBtn?: boolean,
            refreshPointer:boolean
        },
        events: {
            refresh: Function,
            add: Function,
            edit: Function,
            delete: Function,
            menuOpen:Function
        },

    }

    export class AccountsController extends Core.Controller {
        public static $inject = [Globals.scope, Globals.dialogs, Globals.translate, Globals.toaster, 'bankingService', 'accountService', '$log'];

        constructor(private $scope: IAccountsScope, private dialogs: any, private $translate: any, private toaster: any, private bankingService: Banking.Services.Banking, private accountService: Account.Services.Account, private $log) {
            super(arguments, AccountsController.$inject);
            this.initializatiuon($scope);
        }

        initializatiuon = (scope: IAccountsScope) => {
            scope.Components = {
                bankAccountGrid: this.initPosListGrid(),
            };
            scope.Model = {
                disableBtn: true,
                refreshPointer:true
            };
            scope.events = {
                refresh: this.refresh,
                add: this.add,
                edit: this.edit,
                delete: this.delete,
                menuOpen:this.menuOpen
            };

        }
        onCompanyChange = (company: Apex.UIFrame.Interfaces.ICompanyInfo) => {
            this.$scope.Model.disableBtn=true;
            this.$scope.Components.bankAccountGrid.grid.clearSelection();
            this.refresh();
        }
        menuOpen =()=>{
            if (this.$scope.Model.disableBtn) {
                $("#bank-account-edit-menu").css("opacity", "0.4");
                $("#bank-account-delete-menu").css("opacity", "0.4");
            } else {
                $("#bank-account-edit-menu").css("opacity", "1");
                $("#bank-account-delete-menu").css("opacity", "1");
            }
        }
        initPosListGrid = () => {
            var model={
                id:"ID",
                fields:{
                    Name:{type:"string"},
                    Acc:{type:"string"},
                    IbanAcc:{type:"string"},                    
                    Bank:{type:"string"},
                    CurrencyCode:{type:"string"},
                    BankProvider:{type:"string"}
                }
            };
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: Core.Kendo.GridView.initAutoQueryDataSource(this.bankingService.getBAccountsUrl, model),
                    columns: this.accountsColumns,
                    settings: { editble: { disableEditRow: true } },
                    parentScope: this.$scope,
                    gridEvents: [
                        {
                            eType: Components.EventType.Change,
                            eFunction: (e) => {
                                Core.Utils.Helper.safeApply(this.$scope, () => {
                                    var rows = e.sender.select()
                                    this.$scope.Model.disableBtn = !(rows.length > 0);
                                });
                            }
                        },
                        {
                            // eType: Components.EventType.ISelectedChange,
                            // customFunction: (e: Apex.Components.Directives.ISelectedEvent) => {
                            //     if (e.AllowToChange && e.newValue)
                            //         this.$scope.selectedItemsCount++;
                            //     else if (e.AllowToChange && !e.newValue)
                            //         this.$scope.selectedItemsCount--;

                            //     this.$scope.disabled.SaveBtn = !(this.$scope.selectedItemsCount > 0);
                            //     return e;
                            // }
                        }
                    ]

                };

            return grid;
        }
        openModalForm = (data = null) => {
            var dlg = Core.Utils.ModalForm.openModalForm("accounts.modal.html", Controllers.AccountsModal.getName(), data, Core.Utils.ModalSize.Medium);
            dlg.result.then(result => {
                if (typeof result !== "undefined")
                    this.$scope.Components.bankAccountGrid.grid.dataSource.read();
            });
        }
        refresh = () => {
            this.$scope.Model.refreshPointer=false;
            this.$scope.Components.bankAccountGrid.gridView.showLoading();
            this.$scope.Components.bankAccountGrid.grid.dataSource.fetch().always(r=>{
                this.$scope.Components.bankAccountGrid.gridView.hideLoading();
            });
        }
        add = () => {
            this.openModalForm();
        }
        edit = () => {
            var selectedItem = this.$scope.Components.bankAccountGrid.grid.select();
            if (selectedItem && selectedItem.length > 0) {
                var dataItem = this.$scope.Components.bankAccountGrid.grid.dataItem(selectedItem[0]);
                if (dataItem) {
                    this.openModalForm(dataItem);
                }
            }
        }
        delete = () => {
            var selected = this.$scope.Components.bankAccountGrid.grid.select();
            if (selected.length == 0)
                return;
            var dataItem: any = this.$scope.Components.bankAccountGrid.grid.dataItem(selected[0]);
            if (!dataItem)
                return;
            var dlg = this.dialogs.confirm(this.$translate.instant('Attention'), this.$translate.instant('deleteConfirmation'));
            dlg.result.then(btn => {
                this.bankingService.deleteBAccount(dataItem.ID).then(results => {
                    this.toaster.pop('success', this.$translate.instant('Info'), this.$translate.instant('acc') + " " + this.$translate.instant('successDeleted'));
                    this.$scope.Components.bankAccountGrid.grid.dataSource.read();
                }).catch(errpr => this.toaster.pop('error', "Error", this.$translate.instant('acc') + " " + this.$translate.instant('dontDelete')));
            });
        }


        private accountsColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: 'Name', titleTranslated: 'nameCaption', width: 200 },
            { field: 'Acc', titleTranslated: 'acc', width: 300 },
            { field: 'IbanAcc', titleTranslated: 'ibanAcc', width: 200 },
            { field: 'Bank', titleTranslated: 'bank', width: 200 },
            { field: 'CurrencyCode', titleTranslated: 'Currency', width: 100 },
            { field: 'BankProvider', titleTranslated: 'provider' }
        ];
    }
}
