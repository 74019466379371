/// <reference path='../../_reference.ts' />
module Apex.Banking.Controllers {
    export interface AccFilterScope extends angular.IScope {
        events: {
            closeForm: Function,
            save: Function
        },
        FilterGroupOptions: Core.Interfaces.IFilterGroupOptions;
        model:{
            priorityLevel?:number
        },
        priorityInputOption: {
            numeric:boolean,
            allowMinus: boolean, //false
            min: number, //0.0001
            numberType: Components.NumberType
        };
    }
    declare var window: any;

    export class AccFilterModal extends Core.Controller {
        public static $inject = [Globals.scope, Globals.translate, 'data', 'bankingService', Globals.modalInstance, Globals.toaster, "$timeout"];
        constructor(private $scope: AccFilterScope, private $translate, data: any, private bankingService,
            private $uibModalInstance, private toaster, public $timeout) {
            super(arguments, AccFilterModal.$inject);
            this.initialization($scope);
        }

        initialization = (scope: AccFilterScope) => {
            scope.priorityInputOption={
                allowMinus:false,
                numeric:true,
                min:0,
                numberType:Components.NumberType.Integer
            }
            scope.model={};

            scope.events = {
                closeForm: this.closeForm,
                save: this.save
            }
            scope.FilterGroupOptions = {
                fields: {
                    Id: 0,
                    //[Description("საბუთის ბუღალტრულ ჟურნალში ჩამბის იდენტიფიკატორი")]
                    BookId: "",
                    //[Description("დებეტი (ბალანსში)")]
                    DB: "",
                    //[Description("კრედიტი (ბალანსში)")]
                    CR: "",
                    BdiType: "",
                    //[Description("ბანკის იდენთიფიკატორი (პროვაიდერი : BOG, TERA, TBC)")]
                    Provider: "",
                    //[Description("საბუთის შექმნის თარიღი")]
                    DocDate: new Date(),
                    //[Description("ვალუტის სახეობა")]
                    ISO: "",
                    Amount: 0,
                    //[Description("ექვივალენტი (თანხა)")]
                    AmountEQU: 0,
                    //[Description("საბუთის №")]
                    DocNum: 0,
                    //[Description("ოპერაციის კოდი")]
                    OpCode: "",
                    //[Description("დებეტი (ბანკი - ამოკითხული .xls  ფაილიდან)")]
                    Debit: "",
                    //[Description("კრედიტი (ბანკი - ამოკითხული .xls  ფაილიდან)")]
                    Credit: "",
                    //[Description("გადახდის დანიშნულება")]
                    Descrip: "",
                    //[Description("ბანკის კოდი (გამგზავნი)")]
                    SenderBankCode: "",
                    //[Description("ბანკის დასახელება (გამგზავნი)")]
                    SenderBankName: "",
                    //[Description("ანგარიშსწორების/ანგარიში (გამგზავნი)")]
                    SenderAcc: "",
                    //[Description("ანგარიშსწორების/ანგარიშის დასახელება  (გამგზავნი)")]
                    SenderAccName: "",
                    //[Description("ბანკის კოდი (მიმღები)")]
                    RecieverBankCode: "",
                    //[Description("ბანკის დასახელება (მიმღები)")]
                    RecieverBankName: "",
                    //[Description("ანგარიშსწორების/ანგარიში (მიმღები)")]
                    RecieverAcc: "",
                    //[Description("ანგარიშსწორების/ანგარიშის დასახელება  (მიმღები)")]
                    RecieverAccName: "",
                    //[Description("საიდენტიფიკაციო კოდი (გამგზავნი)")]
                    SenderTaxCode: "",
                    //[Description("საიდენტიფიკაციო კოდი (მიმღები)")]
                    RecieverTaxCode: "",
                    //[Description("ხაზინის კოდი")]
                    SaxazCode: "",
                    //[Description("ოპერაციის №")]
                    OpNum: 0,
                    RecId: 0,
                    CRTime: new Date(),
                    PaymentId: "",
                    TransactionId: ""
                }
            };
        }

        closeForm = () => {
            this.$uibModalInstance.close(false);
        }
        save = () => {
            this.$scope.FilterGroupOptions.GetFilterCriteria();
            var data=<Interface.AccFilter>{
                        FilterStringCriteria:this.$scope.FilterGroupOptions.stringCriteria,
                        Priority: this.$scope.model.priorityLevel,
                        Value: this.$scope.FilterGroupOptions.value
                    }
            this.$uibModalInstance.close(data);
        }


    }

}