/// <reference path='../_reference.ts' />
module Apex.Banking.Controllers {

    export interface PosListScope extends angular.IScope {
        Components: {
            posListGrid: Apex.Components.IApxGrid
        },
        events: {
            refresh: Function,
            add: Function,
            edit: Function,
            delete: Function,
            menuOpen:Function
        },
        model: {
            disableButton?: boolean
            refreshPointer:boolean
        }

    }

    export class PosList extends Core.Controller {

        public static $inject = [Globals.scope, Globals.toaster, 'bankingService', Globals.stateParams, '$translate'];

        constructor(private $scope: PosListScope, private toaster: any, private bankingService: Services.Banking, private $stateParams, private $translate) {
            super(arguments, PosList.$inject);
            this.initialize($scope);
        }

        initialize = (scope: PosListScope) => {
            scope.Components = {
                posListGrid: this.initPosListGrid(),
            };
            scope.model = {
                disableButton: true,
                refreshPointer:true
            };

            scope.events = {
                refresh: this.refresh,
                add: this.add,
                delete: this.delete,
                edit: this.edit,
                menuOpen:this.menuOpen
            };
        }
        onCompanyChange = (company: Apex.UIFrame.Interfaces.ICompanyInfo) => {
            this.$scope.model.disableButton=true;
            this.$scope.Components.posListGrid.grid.clearSelection();
            this.refresh();
        }

        menuOpen =()=>{
            if (this.$scope.model.disableButton) {
                $("#pos-list-edit-menu").css("opacity", "0.4");
                $("#pos-list-delete-menu").css("opacity", "0.4");
            } else {
                $("#pos-list-edit-menu").css("opacity", "1");
                $("#pos-list-delete-menu").css("opacity", "1");
            }
        }
        refresh = () => {
            this.$scope.model.refreshPointer=false;
            this.$scope.Components.posListGrid.gridView.showLoading();
            this.bankingService.getPosList().then(r => {
                this.$scope.Components.posListGrid.gridView.hideLoading();
                if (r) {
                    if(!r.Result || r.Result.length==0)
                        this.$scope.model.disableButton=true;
                    this.$scope.Components.posListGrid.dataSource.data(r.Result);
                }
            }).catch(error => this.$scope.Components.posListGrid.gridView.hideLoading());
        }

        add = () => {
            this.openModalForm();
        }
        edit = () => {
            var selected = this.$scope.Components.posListGrid.grid.select();
            var dataItem: Interface.IPosList = this.$scope.Components.posListGrid.grid.dataItem(selected).toJSON();
            if (dataItem) {
                this.openModalForm(dataItem.Id);
            }
        }

        delete = () => {
            var selected = this.$scope.Components.posListGrid.grid.select();
            var dataItem: Interface.IPosList = this.$scope.Components.posListGrid.grid.dataItem(selected).toJSON();
            if (dataItem) {
                Core.Utils.ModalForm.openDeleteConfirmations(() => {
                    this.$scope.Components.posListGrid.gridView.showLoading();
                    this.bankingService.deletePosList([dataItem.Id]).then(r => {
                        this.$scope.Components.posListGrid.gridView.hideLoading();
                        this.refresh();
                    }).catch(err => this.$scope.Components.posListGrid.gridView.hideLoading());
                });
            }
        }

        openModalForm = (data = null) => {
            Core.Utils.ModalForm.openModalForm('pos.list.modal.html', PosListModal.getName(), data, Core.Utils.ModalSize.Small)
                .result.then(result => {
                    if (result) {
                        this.refresh();
                    }
                });
        }

        initPosListGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.getGridDatasource(),
                    columns: this.statementColumns,
                    settings: { editble: { disableEditRow: true } },
                    parentScope: this.$scope,
                    gridEvents: [
                        {
                            eType: Components.EventType.Change,
                            eFunction: (e) => {
                                Core.Utils.Helper.safeApply(this.$scope, () => {
                                    var rows = e.sender.select()
                                    this.$scope.model.disableButton = !(rows.length > 0);
                                });
                            }
                        },
                        {
                            // eType: Components.EventType.ISelectedChange,
                            // customFunction: (e: Apex.Components.Directives.ISelectedEvent) => {
                            //     if (e.AllowToChange && e.newValue)
                            //         this.$scope.selectedItemsCount++;
                            //     else if (e.AllowToChange && !e.newValue)
                            //         this.$scope.selectedItemsCount--;

                            //     this.$scope.disabled.SaveBtn = !(this.$scope.selectedItemsCount > 0);
                            //     return e;
                            // }
                        }
                    ]

                };

            return grid;
        }
        getGridDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                schema:{
                    model:{
                        id:"Id",
                       fields:{
                           Id:{type:"number"},
                           Acc:{type:"string"},
                           AccNu:{type:"string"},
                           PosCode:{type:"string"},
                           Note:{type:"string"}
                       }
                    }
                }
            });
        }


        private statementColumns: Array<Core.Interfaces.IGridColumn> = [
            { field: "Id", title: "Id", hidden: true },
            { field: "Acc", titleTranslated: "acc", width: 170 },
            { field: "AccNu", titleTranslated: "caption",width: 200 },
            { field: "PosCode", titleTranslated: "code", width: 170 },
            { field: "Note", titleTranslated: "note" }
        ];
    }
}
