/// <reference path='./../_reference.ts' />
module Apex.Banking.Services {
    'use strict';

    export class Banking extends Core.Service {
        getBAccountsUrl = '/Banking/BankAccounts';

        autoFillBanckAccountUrl = "/Banking/BDData/Account";
        deleteBDData(bankOperationIds: number[]) {
            return this.httpDelete("/Banking/BDData/" + bankOperationIds);
        }
        deletePosList(posListIds: number[]): angular.IPromise<Interface.DeletePosListResponse> {
            return this.httpDelete("/Banking/PosTerminals?PosIds=" + posListIds);
        }
        savePosLis(posList: Interface.IPosList): angular.IPromise<Interface.SavePosListResponse> {
            return this.httpPost("/Banking/PosTerminals", { PosList: posList });
        }
        getPosList(posId: number = null): angular.IPromise<Interface.GetPosListResponse> {
            var params = {
                nm: 'PosId={0}',
                pr: [posId]
            };
            return this.httpGet("/Banking/PosTerminals", params);
        }



        deleteAccFilter(id: number): angular.IPromise<Interface.DeleteAccFilerResponse> {
            return this.httpDelete("/Banking/AccFilters/" + id);
        }
        saveAccFilter(AccFilter: Interface.AccFilter): angular.IPromise<Interface.SaveAccFilterResponse> {
            return this.httpPost("/Banking/AccFilters", { AccFilter: AccFilter });
        }
        getAccFilter(): angular.IPromise<Interface.GetAccFilterResponse> {
            return this.httpGet("/Banking/AccFilters");
        }


        getBdiAccounts(item: Interface.BDData, acc: string = null): angular.IPromise<any> {
            var params = {
                nm: 'Acc={0}&BDDataId={1}',
                pr: [acc, item.Id]
            };
            return this.httpGet("/json/reply/GetBdiAccounts", params);
        }
        recordInTheBalanceSheet(data: any, operId: string): angular.IPromise<any> {
            return this.httpPost("/json/reply/RecordInTheBalanceSheet", { BDData: data, OperId: operId });
        }

        autoFillBanckAccount(data: any, idAcc: string, iBanAcc: string): angular.IPromise<any> {
            return this.httpPost(this.autoFillBanckAccountUrl, { Data: data, IdAcc: idAcc, IBanAcc: iBanAcc });
        }
        ///get data from bdi.BD_Data
        getOperation(D1: string, D2: string, Acc: string, BdiType: boolean): angular.IPromise<any> {
            var params = {
                nm: 'D1={0}&D2={1}&Acc={2}&BdiType={3}',
                pr: [D1, D2, Acc, BdiType]
            };
            return this.httpGet("/Banking/BDData", params);
        }
        saveBankTransaction(data): angular.IPromise<any> {
            return this.httpPost("/Banking/BDData", { BDData: data });
        }

        getBAccounts(): any {
            return this.httpGet(this.getBAccountsUrl);
        }

        deleteBAccount(id): angular.IPromise<any> {
            return this.httpDelete('/Banking/BankAccounts/' + id);
        }

        saveBAccount(bAcc: any): angular.IPromise<any> {
            return this.httpPost('/Banking/BankAccounts', { BankAccount: bAcc });
        }

        getBanks(): angular.IPromise<any> {
            return this.httpGet('/Common/Bank');
        }

        getBankTransactions(d1, d2, acc, page, pageSize, token): angular.IPromise<any> {
            this.http.defaults.headers.get = { 'X-Banking-Authorization-Token': token };
            var bankAccount = {
                AccountNumber: acc.IbanAcc,
                CurrencyCode: acc.CurrencyCode
            }
            var params = {
                nm: 'D1={0}&D2={1}&Account={2}&PageSize={4}&Page={3}',
                pr: [d1, d2, JSON.stringify(bankAccount).replace(/['"]+/g, ''), page, pageSize]
            };
            return this.httpGet('/Banking/' + acc.BankProvider + '/Transactions.json', params);
        }

        getBankProvidersInfo(): angular.IPromise<any> {
            return this.httpGet('/Banking/Providers');
        }

        getBogToken(): angular.IPromise<any> {
            return this.httpGet("/BOG/Token");
        }

        changeProviderAuth(providerCode: string, authName: string, oldPassword: string, newPassword: string, authCode: string): angular.IPromise<any> {
            var params = {
                AuthName: authName,
                NewPassword: newPassword,
                OldPassword: oldPassword,
                AuthorizationToken: authCode
            };
            return this.httpPut("/Banking/" + providerCode + "/Password", params);
        }


        // getBogToken(code): angular.IPromise<any> {
        //     var params = {
        //         grant_type: 'sKWzMF9KeuYPLl8n2TwXzjWULn+kJfI+a7DJdlMDTT3SQ/2CfcUa9qt/gwDaon8M3tphDGScSK6CfMypler2sA==',
        //         code: code,
        //         redirect_uri: 'https://10.10.0.74:88/bankToken/bog'
        //     };
        //     return this.httpPost('https://cib2-web-dev.bog.ge:8090/api/connect/token/', params);
        // }
        getBogAuth(): angular.IPromise<any> {
            return this.httpGet("/BOG/Auth");
        }

    }
}
