/// <reference path='../../../Apex.WebUI.Core/app/Interfaces/ICoreInterface.ts' />

module Apex.Banking.Interface {
    "use strict";

// @DataContract
export interface DeletePosListResponse
{
    // @DataMember(Order=1)
    ResponseStatus?: Apex.Core.Interfaces.ResponseStatus;
}
// @DataContract
export interface SavePosListResponse
{
    // @DataMember(Order=1)
    ResponseStatus?: Apex.Core.Interfaces.ResponseStatus;

    // @DataMember(Order=2)
    Result?: number;
}

// @DataContract
export interface GetPosListResponse
{
    // @DataMember(Order=1)
    ResponseStatus?: Apex.Core.Interfaces.ResponseStatus;

    // @DataMember(Order=2)
    Result?: IPosList[];
}

// @DataContract
export interface IPosList
{
    // @DataMember(Order=1)
    Id?: number;

    // @DataMember(Order=2)
    PosCode?: string;

    // @DataMember(Order=3)
    Note?: string;

    // @DataMember(Order=4)
    Acc?: string;

    // @DataMember(Order=5)
    AccNu?: string;
}

export interface SaveAccFilterResponse
{
    // @DataMember(Order=1)
    ResponseStatus?: Apex.Core.Interfaces.ResponseStatus;

    // @DataMember(Order=2)
    Result?: number;
}

// @DataContract
export interface DeleteAccFilerResponse
{
    // @DataMember(Order=1)
    ResponseStatus?: Apex.Core.Interfaces.ResponseStatus;
}




    // @DataContract
    export interface AccFilter {
        // @DataMember(Order=1)
        Id?: number;
        // @DataMember(Order=2)
        FilterStringCriteria?: string;
        // @DataMember(Order=3)
        Value?: string;
        // @DataMember(Order=4)
        Priority?: number;
    }

    // @DataContract
    export interface GetAccFilterResponse {
        // @DataMember(Order=1)
        ResponseStatus: Apex.Core.Interfaces.ResponseStatus;

        // @DataMember(Order=2)
        Result: AccFilter[];
    }


    export interface BankingAmount {
        Amount?: number;
        CurrencyCode?: string;
    }


    // @DataContract

    export interface BankTransaction extends Apex.Core.Interfaces.ISelected {
        AccountName?: string;
        AccountNumber?: string;
        AdditionalDescription?: string;
        AdditionalInfo?: string;
        Amount?: BankingAmount,
        ChargeDetail?: string;
        DebitOrCredit?: number;
        Description?: string;
        DocumentDate?: Date;
        DocumentNumber?: string;
        ExternalPaymentId?: string;
        IntermediaryBank?: string;
        IntermediaryBankCode?: string;
        OperationCode?: string;
        ParentExternalPaymentId?: string;
        PartnerAccountNumber?: string;
        PartnerBank?: string;
        PartnerBankCode?: string;
        PartnerDocumentNumber?: string;
        PartnerDocumentType?: string;
        PartnerName?: string;
        PartnerPersonalNumber?: string;
        PartnerTaxCode?: string;
        PaymentId?: string;
        Rate?: number;
        StatusCode?: string;
        TaxPayerCode?: string;
        TaxPayerName?: string;
        TransactionDate?: Date;
        TransactionId?: string;
        TransactionType?: string;
        TreasuryCode?: string;
    }

    export interface BDData extends Apex.Core.Interfaces.ISelected {
        Id?: number;
        //[Description("საბუთის ბუღალტრულ ჟურნალში ჩამბის იდენტიფიკატორი")]
        DocId?: string;
        //[Description("დებეტი (ბალანსში)")]
        DB?: string;
        //[Description("კრედიტი (ბალანსში)")]
        CR?: string;
        BdiType?: boolean;
        //[Description("ბანკის იდენთიფიკატორი (პროვაიდერი : BOG, TERA, TBC)")]
        Provider?: string;
        //[Description("საბუთის შექმნის თარიღი")]
        DocDate?: Date;
        //[Description("ვალუტის სახეობა")]
        ISO?: string;
        Amount?: number;
        //[Description("ექვივალენტი (თანხა)")]
        AmountEQU?: number;
        //[Description("საბუთის №")]
        DocNum?: number;
        //[Description("ოპერაციის კოდი")]
        OpCode?: string;
        //[Description("დებეტი (ბანკი - ამოკითხული .xls  ფაილიდან)")]
        Debit?: string;
        //[Description("კრედიტი (ბანკი - ამოკითხული .xls  ფაილიდან)")]
        Credit?: string;
        //[Description("გადახდის დანიშნულება")]
        Descrip?: string;
        //[Description("ბანკის კოდი (გამგზავნი)")]
        SenderBankCode?: string
        //[Description("ბანკის დასახელება (გამგზავნი)")]
        SenderBankName?: string;
        //[Description("ანგარიშსწორების/ანგარიში (გამგზავნი)")]
        SenderAcc?: string;
        //[Description("ანგარიშსწორების/ანგარიშის დასახელება  (გამგზავნი)")]
        SenderAccName?: string;
        //[Description("ბანკის კოდი (მიმღები)")]
        RecieverBankCode?: string;
        //[Description("ბანკის დასახელება (მიმღები)")]
        RecieverBankName?: string;
        //[Description("ანგარიშსწორების/ანგარიში (მიმღები)")]
        RecieverAcc?: string;
        //[Description("ანგარიშსწორების/ანგარიშის დასახელება  (მიმღები)")]
        RecieverAccName?: string;
        //[Description("საიდენტიფიკაციო კოდი (გამგზავნი)")]
        SenderTaxCode?: String;
        //[Description("საიდენტიფიკაციო კოდი (მიმღები)")]
        RecieverTaxCode?: String;
        //[Description("ხაზინის კოდი")]
        SaxazCode?: string;
        //[Description("ოპერაციის №")]
        OpNum?: number;
        RecId?: number;
        CRTime?: Date;
        PaymentId?: string;
        TransactionId?: string;
    }
}