/// <reference path='../../_reference.ts' />
module Apex.Banking.Controllers {
    export interface ChangeProviderAuthScope extends angular.IScope {
        account: any
        events: {
            closeForm: Function,
            save: Function,
            onLoad: Function
        },
        model: {
            AuthName: string,
            OldPassword: string,
            NewPassword: string,
            NewPassword2: string,
            AuthorizationToken: string
        }
    }
    declare var window: any;

    export class ChangeProviderAuthModal extends Core.Controller {
        public static $inject = [Globals.scope, Globals.translate, 'data', 'bankingService', Globals.modalInstance, Globals.toaster, "$timeout", '$sce', '$location', '$rootScope'];
        constructor(private $scope: ChangeProviderAuthScope, private $translate, data: any, private bankingService,
            private $uibModalInstance, private toaster, public $timeout, private $sce,
            private $location, private $rootScope) {
            super(arguments, ChangeProviderAuthModal.$inject);
            $scope.account = data;
            $scope.model = {
                AuthName: "",
                OldPassword: "",
                NewPassword: "",
                NewPassword2: "",
                AuthorizationToken: ""
            };
            this.initialization();
        }

        initialization = () => {
            this.$scope.events = {
                closeForm: this.closeForm,
                save: this.save,
                onLoad: this.onLoad
            }
        }

        onLoad = () => {
            
        }

        closeForm = () => {
            this.$uibModalInstance.close(false);
        }
        save = () => {
            this.bankingService.changeProviderAuth(
                this.$scope.account.BankProvider,
                this.$scope.model.AuthName,
                this.$scope.model.OldPassword,
                this.$scope.model.NewPassword,
                this.$scope.model.AuthorizationToken).then(result => {
                    if (result) {
                        this.$uibModalInstance.close(true);
                    }
                });
        }
    }
}