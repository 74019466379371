/// <reference path='../../_reference.ts' />
module Apex.Banking.Controllers {
    export interface PosListModalScope extends angular.IScope {
        modalTitle: String,
        events: {
            closeForm: Function,
            save: Function
        },
        model: {
            id: number,
            posList: Interface.IPosList
        },
        posListModalForm: any
    }

    export class PosListModal extends Core.Controller {
        public static $inject = [Globals.scope, Globals.translate, 'data', 'bankingService', Globals.modalInstance, Globals.toaster, "$timeout"];
        constructor(private $scope: PosListModalScope, private $translate, data: any, private bankingService: Apex.Banking.Services.Banking,
            private $uibModalInstance, private toaster, public $timeout) {
            super(arguments, PosListModal.$inject);
            this.initialization($scope);
            $scope.modalTitle = data ? "რედაქტირება" : "დამატება";

            if (data) {
                $scope.model.posList.Id = data;
                $scope.model.id = data;
                this.getPosList($scope.model.id);
            }

        }

        initialization = (scope: PosListModalScope) => {
            scope.model = {
                id: 0,
                posList: <Interface.IPosList>{}
            };
            scope.events = {
                closeForm: this.closeForm,
                save: this.save
            };
        }


        closeForm = () => {
            this.$uibModalInstance.close(false);
        }

        save = () => {
            if (this.$scope.model.posList && this.$scope.model.posList.Acc && this.$scope.model.posList.PosCode) {
                this.bankingService.savePosLis(this.$scope.model.posList).then(r => {
                    if (r && r.Result > 0)
                        this.$uibModalInstance.close(true);
                });
            } else this.toaster.pop("warning","შენიშვნა!","გთხოვთ შეავსეთ აუცილებელი ველები.");
        }

        getPosList = (id: number = null) => {
            this.bankingService.getPosList(id).then(r => {
                if (r) {
                    this.$scope.model.posList = r.Result.length > 0 ? r.Result[0] : null;
                }
            });
        }

    }
}