/// <reference path='../_reference.ts' />
module Apex.Banking.Controllers {
    export interface StatementScope extends angular.IScope {
        Components: {
            statementGrid: Apex.Components.IApxGrid
        },
        Model: {
            bankAccounts?: any,
            dataSource: any[],
            refreshPointer: boolean,
            needGridSync: boolean
        },
        Filters: {
            dateBetween?: any,
            accId?: number
        },
        bankProvidersInfo: any[],
        events: {
            refresh: Function,
            save: Function,
            selectAll: Function,
            unselectAll: Function,
            checkedChange: Function,
            changeBankAuth: Function
        },
        token: any;
        disabled: {
            SelectBtn: boolean,
            SaveBtn: boolean
        };
        selectedItemsCount: number;
        paging: {
            page: number,
            pageSize: number
        }
    }

    export class Statement extends Core.Controller {

        public static $inject = [Globals.scope, Globals.toaster, 'bankingService', Globals.stateParams, '$translate'];

        constructor(private $scope: StatementScope, private toaster: any, private bankingService: Services.Banking, private $stateParams, private $translate) {
            super(arguments, Statement.$inject);
            this.initialize();
        }

        initialize = () => {
            this.$scope.paging = {
                page: 0,
                pageSize: 100
            };
            this.$scope.selectedItemsCount = 0;
            this.$scope.disabled = {
                SaveBtn: true,
                SelectBtn: true
            };
            this.$scope.token = {};
            this.$scope.Model = {
                dataSource: [],
                refreshPointer: true,
                needGridSync: false
            };
            this.$scope.Filters = {
                dateBetween: {}
            };
            this.$scope.Components = {
                statementGrid: this.initStatementGrid(),
            }
            this.$scope.events = {
                refresh: this.refresh,
                save: this.save,
                selectAll: () => {
                    var dataSource = this.$scope.Components.statementGrid.dataSource.data();
                    _.forEach(dataSource, (item) => {
                        if (!item.isSelected) {
                            this.$scope.Model.needGridSync = true;
                            item.isSelected = true;
                            this.checkedChange(item.isSelected);
                        }
                    });
                    if (this.$scope.Model.needGridSync) {
                        this.$scope.Components.statementGrid.dataSource.sync();
                        this.$scope.Model.needGridSync = false;
                    }
                },
                unselectAll: () => {
                    var dataSource = this.$scope.Components.statementGrid.dataSource.data();
                    _.forEach(dataSource, (item) => {
                        if (item.isSelected) {
                            this.$scope.Model.needGridSync = true;
                            item.isSelected = false;
                            this.checkedChange(item.isSelected);
                        }
                    });
                    if (this.$scope.Model.needGridSync) {
                        this.$scope.Components.statementGrid.dataSource.sync();
                        this.$scope.Model.needGridSync = false;
                    }
                },
                checkedChange: this.checkedChange,
                changeBankAuth: this.showChangeBankAuthModal

            };

            this.getBankProvidetsInfo();
            this.getBankAccounts();
        }
        onCompanyChange = (company: Apex.UIFrame.Interfaces.ICompanyInfo) => {
            this.$scope.disabled.SaveBtn =
                this.$scope.disabled.SelectBtn = true;
            this.$scope.Filters.accId = null;
            this.getBankAccounts();
        }

        refresh = () => {
            this.$scope.Model.refreshPointer = false;
            var account = _.findWhere(this.$scope.Model.bankAccounts, { ID: Number(this.$scope.Filters.accId) });
            if (account != null) {
                switch ((<any>account).BankProvider) {
                    case "BOG": {
                        if (this.$scope.token.BOG) {
                            this.getBankTransaction(this.$scope.token.BOG);
                        } else this.openAuthProvider(account);
                    } break;
                    case "TBC": {
                        if (this.$scope.token.TBC) {
                            this.getBankTransaction(this.$scope.token.TBC);
                        } else this.openAuthProvider(account)
                    } break;
                    case "TERA": {
                        if (this.$scope.token.TERA) {
                            this.getBankTransaction(this.$scope.token.TERA);
                        } else this.openAuthProvider(account)
                    } break;

                }
            }
        }

        save = () => {
            var account = _.findWhere(this.$scope.Model.bankAccounts, { ID: Number(this.$scope.Filters.accId) });
            var data = this.$scope.Components.statementGrid.grid.dataSource.data();
            var selectedData = _.filter(data, (r) => {
                return r.isSelected == true;

            });

            if (data.length > 0) {
                var dataSource: any = new Array<any>();
                if (selectedData.length > 0) {
                    dataSource = selectedData;
                } else {
                    dataSource = data;
                }

                Core.Utils.ModalForm.openConfirmation(selectedData.length > 0 ? "შევინახოთ მონიშნული " + dataSource.length + " ჩანაწერი?"
                    : "შევინახოთ ყველა ჩანაწერი?", false).result.then(() => {
                        this.bankingService.saveBankTransaction(this.BantTransactionToBDData(<any>dataSource, (<any>account))).then(r => {
                            if (r.Result)
                                this.toaster.pop('success', this.$translate.instant('Info'), "მონაცემების შენახვა წარმატებით დასრულდა");
                        });
                    });
            }
        }

        openAuthProvider = (data) => {
            var dlg = Core.Utils.ModalForm.openModalForm('authProviders.modal.html', AuthProvidersModal.getName(), data, Core.Utils.ModalSize.Small);
            dlg.result.then(result => {
                if (result) {

                    switch (data.BankProvider) {
                        case "BOG": this.$scope.token.BOG = result; break;
                        case "TBC": this.$scope.token.TBC = result; break;
                        case "TERA": this.$scope.token.TERA = result; break;
                    }

                    this.getBankTransaction(result);
                }
            });
        }

        getBankTransaction = (token) => {
            this.$scope.Model.dataSource = [];
            var d1 = Core.Utils.Helper.toLocalTimeISO(this.$scope.Filters.dateBetween.fromDate);
            var d2 = Core.Utils.Helper.toLocalTimeISO(this.$scope.Filters.dateBetween.toDate);
            var account = _.findWhere(this.$scope.Model.bankAccounts, { ID: Number(this.$scope.Filters.accId) });
            var bAcc = {
                IbanAcc: (<any>account).IbanAcc,
                CurrencyCode: (<any>account).CurrencyCode,
                BankProvider: (<any>account).BankProvider
            };


            if (this.$scope.Filters.accId) {
                this.$scope.disabled.SaveBtn = (this.$scope.selectedItemsCount = 0) == 0;
                this.$scope.Components.statementGrid.gridView.showLoading();
                this.fetchAllBankTransactions(d1, d2, bAcc, this.$scope.paging, token);
            }

        }
        fetchAllBankTransactions = (d1, d2, bAcc, paging, token) => {
            this.bankingService.getBankTransactions(d1, d2, bAcc, paging.page, paging.pageSize, token).then(r => {
                let data = r.Result;
                for (let i = 0; i < data.length; i++) {
                    data[i].isSelected = false;
                    this.$scope.Model.dataSource.push(data[i]);
                }

                if (r.Result.length == paging.pageSize) {
                    paging.page++;
                    this.fetchAllBankTransactions(d1, d2, bAcc, paging, token);
                } else {
                    this.$scope.paging.page = 0;
                }

                this.$scope.Components.statementGrid.grid.dataSource.data(this.$scope.Model.dataSource);
                this.$scope.Components.statementGrid.gridView.hideLoading();

            }).catch(error => {
                this.$scope.Components.statementGrid.gridView.hideLoading();
                var account = _.findWhere(this.$scope.Model.bankAccounts, { ID: Number(this.$scope.Filters.accId) });
                this.toaster.pop("error", "შეცდომა", "გთხოვთ გაიაროთ ავტორიზაცია '" + (<any>account).BankProvider + "' სერვისზე");
                if (error.success == false && error.statusCode == -1) {
                    this.openAuthProvider(account);
                }
            });
        }

        initStatementGrid = () => {
            var grid: Apex.Components.IApxGrid =
                {
                    dataSource: this.getGridDatasource(),
                    columns: this.statementColumns,
                    settings: { editble: { disableEditRow: true }, pageable: true },
                    parentScope: this.$scope,
                    gridEvents: [
                        {
                            eType: Components.EventType.DataBoud,
                            eFunction: (e) => {
                                Core.Utils.Helper.safeApply(this.$scope, () => {
                                    var rows = e.sender.items();
                                    this.$scope.disabled.SelectBtn = !(rows.length > 0);
                                });

                            }
                        },
                        {
                            eType: Components.EventType.ISelectedChange,
                            customFunction: (e: Apex.Components.Directives.ISelectedEvent) => {
                                if (e.AllowToChange && e.newValue)
                                    this.$scope.selectedItemsCount++;
                                else if (e.AllowToChange && !e.newValue)
                                    this.$scope.selectedItemsCount--;

                                this.$scope.disabled.SaveBtn = !(this.$scope.selectedItemsCount > 0);
                                return e;
                            }
                        }
                    ]

                };

            return grid;
        }
        checkedChange = (isSelected: boolean) => {
            Core.Utils.Helper.safeApply(this.$scope, (scope) => {
                if (isSelected)
                    this.$scope.selectedItemsCount++;
                else
                    this.$scope.selectedItemsCount--;
                this.$scope.disabled.SaveBtn = !(this.$scope.selectedItemsCount > 0);

            });
        }
        getGridDatasource = (): kendo.data.DataSource => {
            return new kendo.data.DataSource({
                pageSize: 100,
                aggregate: [
                    { field: "Amount.Amount", aggregate: "sum" }
                ],
                schema: {
                    model: {
                        fields: {
                            isSelected: { type: 'boolean' },
                            DebitOrCredit: { type: 'number' },
                            PartnerAccountNumber: { type: "string" },
                            PartnerName: { type: "string" },
                            'Amount.Amount': { type: "number" }, ///???
                            'Amount.CurrencyCode': { type: "string" }, ///???
                            DocumentDate: { type: "date" },
                            Description: { type: "string" }
                        }
                    }
                }
            });
        }

        showChangeBankAuthModal = () => {
            if (this.$scope.Filters.accId != null) {
                var account = _.findWhere(this.$scope.Model.bankAccounts, { ID: Number(this.$scope.Filters.accId) });
                if (account != null) {
                    Core.Utils.ModalForm.openModalForm('change.provider.auth.modal.html', ChangeProviderAuthModal.getName(), account, Core.Utils.ModalSize.Small);
                }
            }
        }

        getBankProvidetsInfo = () => {
            this.bankingService.getBankProvidersInfo().then((result: any) => {
                this.$scope.bankProvidersInfo = result.Result;
            });
        }
        getBankAccounts = () => {
            this.bankingService.getBAccounts().then(result => {
                _.each(result.Results, (x: any) => {
                    x.tempalteName = x.IbanAcc + ' ' + x.CurrencyCode;
                });
                this.$scope.Model.bankAccounts = result.Results;
            });
        }

        private statementColumns: Array<Core.Interfaces.IGridColumn> = [
            {
                field: 'isSelected', titleTranslated: "isSelected", width: 40, filterable: 'boolean', template: `
                <div class="checkbox checkbox-styled checkbox-danger" style="margin-bottom: 0px;" >
                    <label>
                        <input type="checkbox"  ng-click="options.parentScope.events.checkedChange(dataItem.isSelected)" ng-model="dataItem.isSelected" ng-true-value="true" ng-false-value="false" >
                        <span ></span>
                    </label>
                </div>`
            },
            {
                field: 'DebitOrCredit', titleTranslated: 'acc_type', width: 100,
                filterable: 'custom',
                format:Core.Kendo.Format.Number,
                filterTemplate: (args) => {
                    var elem: JQuery = args.element;
                    elem.kendoDropDownList({
                        dataSource: [
                            { value: null, text: this.$translate.instant('ALL') },
                            { value: 1, text: this.$translate.instant('CR') },
                            { value: 0, text: this.$translate.instant('DB') }
                        ],
                        valuePrimitive: true,
                        dataTextField: "text",
                        dataValueField: "value",

                    })
                },
                template: '<div ng-if="dataItem.DebitOrCredit>0">კრედიტი</div> <div ng-if="dataItem.DebitOrCredit==0">დებეტი</div>'
            },
            { field: 'PartnerAccountNumber', width: 175, title: 'PartnerAccountNumber', titleTranslated: 'acc' },
            { field: 'PartnerName', title: 'PartnerName', titleTranslated: 'caption', withTooltip: true },
            {
                field: 'Amount.Amount', titleTranslated: 'ammount', width: 90
                , footerTemplate: '<span translate=sum> </span> #= kendo.toString(data["Amount.Amount"]?data["Amount.Amount"].sum.toFixed(2):0) #',
                format:Core.Kendo.Format.Number
            },
            { field: 'Amount.CurrencyCode', titleTranslated: 'Currency', width: 100 },
            { field: 'DocumentDate', titleTranslated: 'DATE', width: 90, format: Core.Kendo.Format.Date },
            { field: 'Description', title: 'Description', titleTranslated: 'Notes', withTooltip: true }
        ];


        BantTransactionToBDData = (data: Array<Interface.BankTransaction>, account): Array<Interface.BDData> => {
            var bdData = new Array<Interface.BDData>();
            for (var i = 0; i < data.length; i++) {
                bdData.push(<Apex.Banking.Interface.BDData>{
                    DB: data[i].DebitOrCredit != 0 ? account.IdAcc : null,
                    CR: data[i].DebitOrCredit != 0 ? null : account.IdAcc,
                    BdiType: data[i].DebitOrCredit != 0 ? false : true,
                    DocDate: data[i].TransactionDate,
                    ISO: data[i].Amount.CurrencyCode.toUpperCase().trim(),
                    Amount: data[i].Amount.Amount,
                    AmountEQU: data[i].Amount.CurrencyCode != "GEL" && data[i].Rate > 0 ? data[i].Amount.Amount * data[i].Rate : data[i].Amount.Amount,
                    OpCode: data[i].OperationCode ? data[i].OperationCode.trim() : "",
                    Debit: data[i].DebitOrCredit == 0 ? data[i].AccountNumber : data[i].PartnerAccountNumber,
                    Credit: data[i].DebitOrCredit == 0 ? data[i].PartnerAccountNumber : data[i].AccountNumber,
                    Descrip: data[i].Description + " " + data[i].AdditionalInfo,
                    SenderBankCode: data[i].DebitOrCredit != 0 ? data[i].PartnerBankCode : data[i].IntermediaryBankCode,
                    SenderBankName: data[i].DebitOrCredit != 0 ? data[i].PartnerBank : data[i].IntermediaryBank,
                    SenderAcc: data[i].DebitOrCredit != 0 ? data[i].PartnerAccountNumber : data[i].AccountNumber,
                    SenderAccName: data[i].DebitOrCredit != 0 ? data[i].PartnerName : data[i].AccountName,
                    RecieverBankCode: data[i].DebitOrCredit == 0 ? data[i].PartnerBankCode : data[i].IntermediaryBankCode,
                    RecieverBankName: data[i].DebitOrCredit == 0 ? data[i].PartnerBank : data[i].IntermediaryBank,
                    RecieverAcc: data[i].DebitOrCredit == 0 ? data[i].PartnerAccountNumber : data[i].AccountNumber,
                    RecieverAccName: data[i].DebitOrCredit == 0 ? data[i].PartnerName : data[i].AccountName,
                    SenderTaxCode: data[i].DebitOrCredit != 0 ? data[i].PartnerTaxCode : data[i].TaxPayerCode,
                    RecieverTaxCode: data[i].DebitOrCredit == 0 ? data[i].PartnerTaxCode : data[i].TaxPayerCode,
                    SaxazCode: data[i].TreasuryCode,
                    RecId: +data[i].TransactionId ? +data[i].TransactionId : 0,
                    PaymentId: data[i].PaymentId,
                    TransactionId: data[i].TransactionId,
                    Provider: account.BankProvider
                });
            }

            return bdData;
        }
    }
}
